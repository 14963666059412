body {
    font-family: 'Poppins';
}

.topnav-logo svg {
    width: 80px;
}

.nav-links {
    display: flex;
    flex-direction: column;
}

.custom-navbar {
    background-color: white;
    color: black;
}

.custom-navbar img {
    width: 180px;
}

.navbar-toggler:hover {
    background-color: white;
}

.navbar-toggler {
    background-color: white;
}

.navbar-toggler, .navbar-toggler:hover {
    width: fit-content !important;
}

.custom-navbar .navbar-nav .nav-link {
    color: black;
    font-size: .9rem;
    padding: 3px 15px;
    font-weight: 550;
}

.custom-navbar .navbar-nav .nav-link:hover {
    color: #d2042d;
}

.custom-navbar .navbar-nav .nav-link:active {
    color: #d2042d;
}

.button-container {
    display: flex;
    gap: 10px;
}


.button-container button {
    background-color: #d2042d;
    color: white;
    border: 2px solid #06211E;
    font-size: 1rem;
    border-radius: 12px !important;
    padding: 5px 10px !important;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    -webkit-box-shadow: 0 5px 0 0 #06211e;
    -moz-box-shadow: 0 5px 0 0 #06211e;
    box-shadow: 0 5px 0 0 #06211e;
}

.button-container button:hover {
    transform: translateY(-3px);
  }

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    margin: 20px auto 0px auto;
}

.transition-button {
    position: relative;
    overflow: hidden;
    transition: 0.6s cubic-bezier(.16, 1, .3, 1) !important;
}

.transition-button .hover-text {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out;
}

.transition-button .default-text {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.transition-button:hover .default-text {
    opacity: 0;
}

.transition-button:hover .hover-text {
    opacity: 1;
}

.button-container .btn-primary {
    background-color: #d2042d;
    border-color: #d2042d;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 1rem;
}

.button-container .btn-primary:hover {
    background-color: #d2042d;
    border-color: #d2042d;
}

.dropdown-container2 {
    position: relative;
    display: inline-block;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-container .dropdown-toggle {
    font-size: 16px;
    font-weight: 500;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dropdown-container2 .dropdown-toggle {
    font-size: 16px;
    font-weight: 500;
    color: black;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dropdown-container .dropdown-menu {
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    min-width: 200px;
    border: none;
    position: absolute;
    z-index: 1000;
}

.dropdown-container2 .dropdown-menu {
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    min-width: 200px;
    border: none;
    position: absolute;
    z-index: 1000;
}

.dropdown-container .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    color: #f7f2f2;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-container2 .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    color: #f7f2f2;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-container .dropdown-item svg {
    margin-right: 10px;

}

.dropdown-container2 .dropdown-item svg {
    margin-right: 10px;
    border: 1px solid white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 3px;
    background-color: #EDF5FD;
    color: #00407D !important;
}

.dropdown-container .dropdown-menu .dropdown-item:hover {
    background-color: #d2042d;
    color: #fff;
}

.dropdown-container2 .dropdown-menu .dropdown-item:hover {
    background-color: #d2042d;
    color: #fff;
}

@media (max-width: 992px) {
    .custom-navbar .navbar-nav .nav-link {
        font-size: 1rem;
        padding: 10px 10px;
    }

    .custom-navbar img {
        width: 100px;
    }

    .button-container .btn-outline-light,
    .button-container .btn-primary {
        padding: 8px 15px;
        font-size: 0.9rem;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: column;
    }

}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
        align-items: center;
        background-color: black;
        padding: 10px;
    }

    .topnav-logo svg {
        width: 65px;
        margin-left: 10px;
    }

    .dropdown-container .dropdown-menu {
        padding: 15px 0;
    }

    .dropdown-container .dropdown-item {
        font-size: 15px;
    }

    .custom-navbar {
        padding: 10px 0;
    }

    .custom-navbar .navbar-nav .nav-link {
        text-align: center;
        padding: 12px 0;
        background-color: black;
        color: white;
    }

    .custom-navbar img {
        width: 90px;
        margin-bottom: 10px;
    }

    .button-container .btn-outline-light,
    .button-container .btn-primary {
        text-align: center;
    }

}