@import '@fontsource/poppins/300.css'; /* Light */
@import '@fontsource/poppins/400.css'; /* Regular */
@import '@fontsource/poppins/600.css'; /* Semi-Bold */
@import '@fontsource/poppins/700.css'; /* Bold */


.spinnerMain {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.spinnerMain svg {
    width: 50px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
    background: #d2042d;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #d2042d;
}

