body {
    font-family: 'Poppins';
   
}

.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.notfound-title {
    font-size: 5vw;  
    margin: 0;
    color: #d2042d;
    animation: pop 0.5s ease-out forwards;
}

.notfound-message {
    font-size: 1.5rem;
    color: black;
    margin-top: 10px;
    padding: 0 10px;
}

.error-icon {
    width: 30%; 
    max-width: 150px; 
    margin: 20px 0;
}

.error-icon svg {
    width: 100%;
    height: 100%;
}

.back-home-button {
   background-color: #d2042d;
    color: white;
    border: 2px solid #06211E;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 12px !important;
    padding: 11px 26px !important;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    -webkit-box-shadow: 0 5px 0 0 #06211e;
    -moz-box-shadow: 0 5px 0 0 #06211e;
    box-shadow: 0 5px 0 0 #06211e;
    max-width: fit-content;
}

.back-home-button:hover {
    background-color: #b0021f;
}

@keyframes pop {
    0% {
        transform: scale(0.9);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .notfound-title {
        font-size: 12vw;
    }

    .notfound-message {
        font-size: 1.2rem;
    }

    .error-icon {
        width: 40%;
    }

    .back-home-button {
        font-size: 0.9rem;
        padding: 10px 18px;
    }
}

@media (max-width: 480px) {
    .notfound-title {
        font-size: 14vw;
    }

    .notfound-message {
        font-size: 1rem;
    }

    .error-icon {
        width: 50%;
    }

    .back-home-button {
        font-size: 0.8rem;
        padding: 8px 16px;
    }
}
